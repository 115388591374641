import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7962ddac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fas fa-shopping-cart" }
const _hoisted_2 = {
  key: 0,
  class: "badge badge-pill"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "mx-0 mx-sm-2 my-2 my-sm-0",
    to: "/cart"
  }, {
    default: _withCtx(() => [
      _createElementVNode("i", _hoisted_1, [
        (_ctx.$store.getters.numberOfItemsInCart)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$store.getters.numberOfItemsInCart), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}