
import { defineComponent } from 'vue'
import { CartItem } from '@/typings';

export default defineComponent({
  name: "CartCardRow",
  props: {
    cartItem: {
      type: Object as () => CartItem,
      required: true
    }
  },
  data() {
    return {
      hovering: false
    }
  },
  methods: {
    removeFromCart() {
      this.$store.commit('removeFromCart', this.$props.cartItem.product);
    }
  }
})
