
  import { defineComponent } from 'vue';
  import ProductModel from '@/models/product.model';

  export default defineComponent({
    name: 'Product',
    props: {
      product: {
        type: ProductModel,
        required: true
      }
    },
    methods: {
      addToCart() {
        this.$store.commit('addToCart', this.product);
      }  
    }
  });

