import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-3 content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Center = _resolveComponent("Center")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$store.state.auth.checked)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : (_openBlock(), _createBlock(_component_Center, {
            key: 1,
            class: "h-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Spinner, {
                size: "8rem",
                thickness: "1.25rem"
              })
            ]),
            _: 1
          }))
    ])
  ], 64))
}