
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    const numberOfItems = 10;
    return {
      numberOfItems
    }
  },
})
