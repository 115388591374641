
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Spinner',
  props: {
    size: {
      type: String,
      default: '4rem'
    },
    thickness: {
      type: String,
      default: '1rem'
    },
    color: {
      type: String,
      default: '#000'
    }
  },
})
