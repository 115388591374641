
import { defineComponent } from 'vue';

import CartCardRow from './CartCardRow.vue';

export default defineComponent({
  name: 'CartCard',
  components: {
    CartCardRow
  }
})
