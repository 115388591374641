
import { defineComponent } from 'vue'

import Navbar from '@/components/navbar';
import Spinner from '@/components/misc/Spinner.vue';
import Center from '@/components/misc/Center.vue';

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    Spinner,
    Center
  }
})
