
import { defineComponent } from 'vue';
import CartIcon from './CartIcon.vue';

export default defineComponent({
  components: {
    CartIcon
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    }
  }
})
