
  import { defineComponent } from 'vue';

  import Product from './Product.vue';

  import PaginationModel from '@/models/pagination.model';
  import ProductModel from '@/models/product.model';

  export default defineComponent({
    name: 'Products',
    components: {
      Product
    },
    props: {
      products: {
        type: Object as () => PaginationModel<ProductModel>,
        required: true
      }
    }
  });

