import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ef250f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right-section" }
const _hoisted_2 = {
  key: 1,
  class: "bg-primary quantity"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: "list-group-item d-flex justify-content-between align-items-center",
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hovering = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hovering = false))
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.cartItem.product.name), 1),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hovering)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeFromCart && _ctx.removeFromCart(...args))),
            class: "btn btn-close"
          }))
        : _createCommentVNode("", true),
      (!_ctx.hovering)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.cartItem.quantity), 1))
        : _createCommentVNode("", true)
    ])
  ], 32))
}