
import { defineComponent } from "vue";

import Products from "@/components/catalog/Products.vue";
import Pagination from "@/components/catalog/Pagination.vue";
import Search from "@/components/catalog/Search.vue";
import CartCard from "@/components/catalog/CartCard.vue";
import Filters from "@/components/catalog/Filters.vue";

import ProductsService from "@/services/resources/products.service";
import ProductModel from "@/models/product.model";
import PaginationModel from "@/models/pagination.model";

const productsService = new ProductsService();

const PAGE_SIZE = 12;

export default defineComponent({
  name: "Catalog",
  components: {
    Products,
    Pagination,
    Search,
    CartCard,
    Filters,
  },
  data() {
    const products = PaginationModel.empty(ProductModel);
    const categories_filter: number[] = [];
    return {
      products,
      categories_filter,
      search_query: "",
      loading: true,
    };
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts(page?: number) {
      page = page || this.products.current_page;
      this.loading = true;
      return productsService
        .query({
          page,
          size: PAGE_SIZE,
          categories: this.categories_filter,
          search: this.search_query || undefined,
        })
        .then((products) => {
          this.products = products;
          this.loading = false;
        });
    },
    handleSearch(query: string) {
      this.search_query = query;
      this.products.current_page = 1;
      this.fetchProducts();
    },
    handleFiltersChange(categories: number[]) {
      this.categories_filter = categories;
      this.products.current_page = 1;
      this.fetchProducts();
    },
  },
});
